const Enkeys: any = {
  'entreposage-libre-service': 'self-storage',
  commercial: 'business',
  residentiel: 'residential',
  demenagement: 'moving',
  'fournitures-emballage': 'packing-supplies',
  'entreposage-mobile': 'portable-storage',
  'entreposage-commercial': 'business-storage',
  'demenagement-longue-distance': 'long-distance-moving',
  'a-propos': 'about',
  faq: 'faq',
  'nous-contacter': 'contact-us',
  offres: 'offers',
  'conditions-dutilisation': 'terms-of-service',
  'politique-de-confidentialite': 'privacy-policy',
  'ressources-communautaires': 'community-resources',
  'soutenir-equipe-canada': 'supporting-team-canada',
  'centre-demenagement': 'moving-centre',
  carrieres: 'careers',
  blogue: 'blog',
  'plus-espace-entreposage': 'extra-storage-space',
  confidentialite: 'privacy',
  'mise-valeur-propriete': 'home-staging',
  'soutenons-canada-soccer': 'supporting-canada-soccer',
  equipecanada: 'team-canada',
  'entreposage-pour-automobile': 'car-storage',
  'entreposage-pour-moto': 'motorcycle-storage',
  'entreposage-pour-rv': 'rv-storage',
  'promotion-etudiante-2022': 'student-special-2022',
  'fournitures-construction': 'construction-supplies',
  'entreposage-automobile': 'automobile-storage',
  'caracteristiques-entreposage-libre-service': 'self-storage-features',
  'unites-d-entreposage-chauffees': 'heated-self-storage-units',
  'commerce-detail': 'retail-inventory',
  'unites-d-entreposage-avec-acces-routier': 'drive-up-units',
  'un-monde-sans-sp':'a-world-without-ms',
  'entreposage-pour-etudiants': 'student-storage',
  recommandation: 'referral',
};
export default Enkeys;
